








import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class HelloWorld extends Vue {

  message = "";

  @Prop()
  private msg1!: string;

  @Prop()
  private msg2!: string;

  @Emit("countUp")
  countUp(){
    return;
  }
  
  @Emit("reset")
  reset(){
    return;
  }

  get GetMessage() :string{
    return this.msg1 + " + " + this.msg2;
  }
}
