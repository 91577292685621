import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Home from "../views/Home.vue";
import Product from "../views/Product.vue";

import ProductDefault from "../views/products/ProductDefault.vue";
import ProductA from "../views/products/ProductA.vue";
import ProductB from "../views/products/ProductB.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
    children: [
      {
        path: "/",
        component:ProductDefault,
      },
      {
        path: "A",
        component:ProductA,
      },
      {
        path: "B",
        component:ProductB,
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
