






















import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Product extends Vue{

	OpenFlag=true;

	get isOpen():boolean{
		return this.OpenFlag;
	}

	navMenu(){
		this.OpenFlag = !this.OpenFlag;
	}

}

