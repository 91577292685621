











import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "../components/HelloWorld.vue"; // @ is an alias to /src

@Component({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {

  firstMessage1 = "Welcome to Your Vue.js";
  firstMessage2 = "TypeScript App!!";

  secondMessage1 = "コンポーネント使いまわし";
  secondMessage2 = "Prop,Emitテスト";

  private count = 0;
  private countUp(){
    this.count++;
  }

  private reset(){
    this.count = 0;
  }

}
